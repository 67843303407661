import { currentAvailableLocales } from '@/i18n/i18nLocales';
import type { ParamMatcher } from '@sveltejs/kit';

const DEBUG = false;

export const match: ParamMatcher = (param: string) => {
    DEBUG && console.log(`Checking locale param match for param: ${param}`);

    const matchingLocale = currentAvailableLocales.find(
        (locale) => locale.toLowerCase() === param.toLowerCase()
    );

    if (!matchingLocale) {
        DEBUG && console.log(`No matching locale found for param: ${param}`);
        return false;
    }

    DEBUG && console.log(`Matched locale: ${matchingLocale}`);
    return true;
};