
import { currentAvailableLocales } from '$lib/i18n/i18nLocales';
import { localeUrls } from '$lib/i18n/i18nLocaleUrls';
import type { ParamMatcher } from '@sveltejs/kit';

const DEBUG = false;

export const match: ParamMatcher = (param) => {
    for (const locale of currentAvailableLocales) {
        if (param === localeUrls[locale]?.blogPageUrl) {
            DEBUG && console.log(`[MATCH:blogPageUrl] ✅ '${param}'`);
            return true;
        }
    }
    DEBUG && console.log(`[MATCH:blogPageUrl] ❌ '${param}'`);
    return false;
};